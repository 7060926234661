/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
html, body { 
    height: 100%;
    font-family: Roboto, "Helvetica Neue", sans-serif; 
}

    
body { margin: 0; }

.detailsLabel{
    font-weight: bold;
}
.cashBagde {
    background:green;
    text-align: center;
    color: white;
    border-radius: 5px 30px;
    padding-top: auto;
    padding-left: 2.5vh;
    padding-right: 2vh;
    margin-bottom: 0.5vh;
}

.entryBagde{
    background: #f44336;
    text-align: center;
    color: white;
    border-radius: 5px 30px;
    padding-top: auto;
    padding-left: 2.5vh;
    padding-right: 2vh;
    margin-bottom: 0.5vh;
}
.itemsBagde{
    background: #17a2b8;
    text-align: center;
    color: white;
    border-radius: 5px 30px;
    padding-top: auto;
    padding-left: 2.5vh;
    padding-right: 2vh;
    margin-bottom: 0.5vh;
}
.live-button{
    color: #adff2f;
}
.sign-up-button{
    color: #ffad41;
}

.Landing-Background{
    /* background: url(https://firebasestorage.googleapis.com/v0/b/carp-contests-app.appspot.com/o/welcomeImage2.jpg?alt=media&token=35ef38ae-b863-4f18-a960-b0146e21f9ee);
    background-size: cover;
    height: 100vh; */
    background: url(src/assets/rsz_welcomeimage3.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* IE */
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='src/assets/rsz_welcomeimage3.jpg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='src/assets/rsz_welcomeimage3.jpg', sizingMethod='scale')";
  height: 100vh;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
